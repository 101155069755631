import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-rfid-self-check"
    }}>{`Configure RFID Self-Check`}</h1>
    <hr></hr>
    <p>{`To enable RFID Self-Check within your mobile app, follow these step-by-step instructions. `}<em parentName="p">{`Please note that RFID check out and check in will only display as options within the Content Management System if you have purchased this functionality. To learn more, please reach out to your support representative:`}</em></p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and click on Channels from the left side menu options. Choose the Channel where you want to add RFID self-check for.`}</li>
      <li parentName="ol">{`Within the selected Channel, click on the 'Circulation' tab.`}</li>
    </ol>
    <p><img alt="CMS Circulation Tab" src={require("./images/self_check_settings.png")} />{` `}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Provide your Institution ID as entered in your ILS in the 'Institution ID' field. `}</li>
      <li parentName="ol">{`(Optional) Fill in the SIP2 Username, Password, Location and Port information if you have location specific SIP2 connection information. `}</li>
      <li parentName="ol">{`Toggle the 'Checkout items' option to enable RFID self-check out. `}</li>
      <li parentName="ol">{`Set the Scan items by: dropdown menu to 'RFID'. `}</li>
      <li parentName="ol">{`Toggle the 'Checkin items' option to enable RFID self-check in. `}</li>
      <li parentName="ol">{`Set the Scan items by: dropdown menu to 'RFID'.`}</li>
      <li parentName="ol">{`Add beacons to to help verify where a patron can use self-check in. Once you receive your beacons, place them near the self-check in location(s). The app will not allow patrons to check in an item if their device is not within range. `}</li>
      <li parentName="ol">{`Provide a name for the beacon that will display in the app. You can enter 200 characters in this field. `}</li>
      <li parentName="ol">{`Provide a description for the beacon that will help your patron locate where the self-check in zone is located. You can enter 600 characters in this field. `}</li>
      <li parentName="ol">{`Set the mode in which the beacon should work. By default, this should be set to Mfr. Default (FDA5).`}</li>
      <li parentName="ol">{`Provide a value in the Major ID and Minor ID fields. `}</li>
      <li parentName="ol">{`Set the maximum range, in meters, from the beacon where the app will allow RFID self-check in. `}</li>
      <li parentName="ol">{`Click 'Add Beacon'. `}</li>
    </ol>
    <p><img alt="CMS Self Check RFID" src={require("./images/self_check_rfid.png")} />{` `}</p>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol">{`Click 'Save' to save the settings for this Channel.`}</li>
      <li parentName="ol">{`Select Mobile Apps from the left side menu options, then select the app you want to enable RFID self-check for.`}</li>
      <li parentName="ol">{`Click on 'Edit' next to the Data Provider.`}</li>
      <li parentName="ol">{`In the Circulation section, toggle 'Self Service'.`}</li>
    </ol>
    <p><img alt="CMS Self-Check Circulation" src={require("./images/self_check_circulation.png")} />{` `}</p>
    <ol {...{
      "start": 20
    }}>
      <li parentName="ol">{`If you want your app to be restricted to only reading the RFID tags your library uses, enter in the ISIL code as defined in the ISO 28560 RFID standard in the 'ISO Institution' field. If left blank, your app will read data from any RFID tag.`}</li>
      <li parentName="ol">{`To restrict access to the self-check function during testing, list the barcodes of the testers in the 'Allowed users' field, separated by commas. After testing, you can remove the usernames to grant access to all patrons.`}</li>
      <li parentName="ol">{`Fill in the SIP2 Server IP, Port, Username, and Password to be used as the main SIP2 connection information. `}</li>
      <li parentName="ol">{`Set the Multi-part RFID dropdown to the desired option:`}
        <ul parentName="li">
          <li parentName="ul">{`Deny won't allow the checkout and will prompt the patron to speak to a staff member.`}</li>
          <li parentName="ul">{`First part only checks out the item and deactives the security in this tag and will ignore the other tags. `}</li>
          <li parentName="ul">{`Allow will have your app check out the item on the ILS, deactivates the security tag, then prompts the patron to tap on the other tags to deactivate the remaining tags. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`If you would like to use the API for self-check, instead of SIP2, toggle 'Use API for Checkout'. `}
        <ul parentName="li">
          <li parentName="ul">{`Note that SIP2 is still required if you want your app to translate from the item barcode to the Title in the checkout display and receipts. SIP2 is also required for check in as there are not check in API's at this time. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Once you've completed the settings, click 'Save' to save the changes.`}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
      <li parentName="ol">{`To ensure proper proper functionality of the self-service feature, it may be necessary to whitelist Solus's IP address. Whitelisting the IP address allows Solus to securely communicate with your system without encountering any restrictions.Please determine which Content Management system stack you use to find the corresponding IP address:`}
        <ul parentName="li">
          <li parentName="ul">{`admin.solus.net.au : 20.92.242.43`}</li>
          <li parentName="ul">{`admin-can1.sol.us : 20.151.130.90`}</li>
          <li parentName="ul">{`admin-eu1.sol.us : 4.185.145.250`}</li>
          <li parentName="ul">{`admin-tlcus1.sol.us : 152.70.192.140`}</li>
          <li parentName="ul">{`admin.solus.co.uk : 82.113.143.229`}</li>
          <li parentName="ul">{`admin-uk2.sol.us : 51.142.87.33`}</li>
          <li parentName="ul">{`admin-us1.sol.us : 20.98.225.203`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      